
section{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: url(./background.jpg);
  background-position-x: center;
  background-size: cover;
  animation: animateBg 30s linear infinite;
  height: auto;
  overflow: hidden;
}


.container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: -500px;
  width: 100% !important;
}
.title{
  padding-top: 50px;
  color: rgb(255, 255, 255);
  margin: auto;
  font-weight: 700;
  font-size: 70px;
  padding-bottom: 10px;
  text-align: center;
  font-style: normal;
  text-shadow: 2px 2px #a09e9e;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.bodyA{
  display: flex;
  height: auto;
  width: 800px;
  justify-content: center;
  margin: auto;
}
.bodyAText{
  color: #ffff;
  text-align: center;
  font-size: 34px;
}


.scene{
  position: relative;
  width: auto;
  display: flex;
  justify-content: center;

}
.rocket{
  position: relative;
  width: 370px;
  height: auto;
  rotate: 10deg;
  margin-top: -300px;
  margin-left:350px;
}
.cardBoxDiv{
  position: relative;
  height: auto;
  width: auto;
  padding-bottom: 40px;
  display: flex;
}
.card{
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 500px;
  justify-content: center;
  background: transparent;
  border-color: white;
  border-radius: 25px;
  box-shadow: 5px white;
  
}

@media only screen and (max-width: 2560px) {
  .containerA{
    margin: auto;
  }
  .bodyAText{
    font-size: 32px;
    width:600px;
  }
  .rocket{
    margin-top: -50px;
    width: 150px;
  }
}
@media only screen and (max-width: 2028px) {
  .container{
    margin-left: -100px;
  }
}
@media only screen and (max-width: 1208px) {
  .container{
    margin-left: 0px;
  }
}
@media only screen and (max-width: 972px) {
  .containerA{
    visibility: hidden;
  }
}
@media only screen and (max-width: 818px) {
  .title{
    margin: auto;
    width: 300px;
  }
  .bodyAText{
    margin: auto;
    width: 300px;
  }
}
@media only screen and (max-width: 607px) {
    .img-fluid{
      width:300px;
      margin: auto;
    }
    .bodyA{
      width: 100%;
      margin: auto;
    }
    .bodyAText{
      font-size: 18px;
      margin: auto;
    }
    .rocket{
      margin: auto;
    }
  
}




.scene .rocket
{
  position: relative;
  animation: rocketanimate 0.4s ease infinite;
}
@keyframes rocketanimate
{
  0%,100%
  {
    transform: translateY(-3px);
  }
  50%
  {
    transform: translateY(3px);
  }
}
span{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1), 0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 1)  ;
  animation:  animate 3s linear infinite;
}
span::before{
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  height: 1px;
  background: linear-gradient(90deg, #fff, transparent);
}
@keyframes animate {
  0%
  {
      transform: rotate(315deg) translateX(-100px);
      opacity: 1;
  }
  70%
  {
      opacity: 1;

  }
  100%
  {
      transform: rotate(315deg) translateX(-1500px);
      opacity: 0;

  }
  
}
span:nth-child(1){
  top: 0;
  right: 1500;
  left:initial;
  animation-delay:0 ;
  animation-duration: 1s;
}

span:nth-child(2){
  top: 0;
  right: 80px;
  left:initial;
  animation-delay:0.2s;
  animation-duration: 3s;
}

span:nth-child(3){
  top: 80px;
  right: 0px;
  left:initial;
  animation-delay:0.4s ;
  animation-duration: 2s;
}

span:nth-child(4){
  top: 0;
  right: 1280px;
  left:initial;
  animation-delay:0.6s;
  animation-duration: 1.5s;
}

span:nth-child(5){
  top: 0;
  right: 400px;
  left:initial;
  animation-delay:0.8s;
  animation-duration: 2.5s;
}

span:nth-child(6){
  top: 0;
  right: 600px;
  left:initial;
  animation-delay:1s ;
  animation-duration: 4s;
}
span:nth-child(7 ){
  top: 300px;
  right: 0px;
  left:initial;
  animation-delay:1s ;
  animation-duration: 1.75s;
}

span:nth-child(8){
  top: 0px;
  right: 700px;
  left:initial;
  animation-delay:1.4s ;
  animation-duration: 1.25s;
}

span:nth-child(9){
  top: 0px;
  right: 1000px;
  left:initial;
  animation-delay:0.75s ;
  animation-duration: 2.25s;
}

span:nth-child(10){
  top: 0px;
  right: 1400px;
  left:initial;
  animation-delay:2.75s ;
  animation-duration: 4.25s;
}
span:nth-child(11){
  top: 0px;
  left: 0px;
  left:initial;
  animation-delay:4.75s ;
  animation-duration: 4.25s;
}

.containerA {
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
}
.containerA .card {
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.containerA .card .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: 0.7s;
  z-index: 1;
}

.containerA .card:nth-child(1) .icon {
  background: #e07768;
}

.containerA .card:nth-child(2) .icon {
  background: #6eadd4;
}

.containerA .card:nth-child(3) .icon {
  background: #4aada9;
}


.containerA .card .icon .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  transition: 0.7s;
  color: #fff;
}

i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  transition: 0.7s;
  color: #fff;
}

.containerA .card .face {
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.containerA .card .face.face1 {
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.containerA .card:hover .face.face1{
  background: #ff0057;
  transform: translateY(0px);
}

.containerA .card .face.face1 .content {
  opacity: 1;
  transition: 0.5s;
}

.containerA .card:hover .face.face1 .content {
  opacity: 1;
}

.containerA .card .face.face1 .content i{
  max-width: 100px;
}

.containerA .card .face.face2 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0,0,0,0.8);
  transform: translateY(-100px);
}

.containerA .card:hover .face.face2{
  transform: translateY(0);
}

.containerA .card .face.face2 .content p {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #414141;
}

.containerA .card .face.face2 .content h3 {
  margin: 0 0 10px 0;
  padding: 0;
  color: #fff;
  font-size: 24px;
  text-align: center;
  color: #414141;
} 

.containerA a {
  text-decoration: none;
  color: #414141;
}